import Panorama from "components/panorama";
import Entry from "components/entry";
import Intro from "components/intro";
import Menu from "components/menu";
import DataPanel from "components/dataPanel";
import { useRef, useMemo, useState, useEffect } from "react";
import getFontPromises from "utils/getFontPromises";
import getImagePromises from "utils/getImagePromises";
import Loader from "components/loader";
import Sound from "components/sound";

const navigateTo = (ref, behavior = "smooth") =>
  ref?.current?.scrollIntoView({ behavior });

const updateNavScroll = (ref) => () => navigateTo(ref, "auto");

const loaderPromises = [...getFontPromises(), ...getImagePromises()];

const App = () => {
  const entryRef = useRef(null);
  const introRef = useRef(null);
  const panoramaRef = useRef(null);
  const dataRef = useRef(null);
  const dataPanelRef = useRef(null);
  const [currentNav, setCurrentNav] = useState("START");
  const [playing, setPlaying] = useState(true);
  const [soundInititated, setSoundInitiated] = useState(false);

  const NAVIGATION_REFS = useMemo(
    () => ({
      START: entryRef,
      INTRO: introRef,
      PANORAMA: panoramaRef,
      DATA: dataRef,
    }),
    [entryRef, introRef, panoramaRef, dataRef]
  );

  const NAVIGATION = useMemo(
    () =>
      Object.entries(NAVIGATION_REFS).reduce((NAVIGATION, navEntry) => {
        const [key, ref] = navEntry;
        return {
          ...NAVIGATION,
          [key]: () => {
            setCurrentNav(key);
            key !== "START" && setSoundInitiated(true); //override browser autoplay policy, if present
            key === "DATA" && setPlaying(false); // turn off sound on last page
            navigateTo(ref);
          },
        };
      }, {}),
    [NAVIGATION_REFS]
  );

  useEffect(() => {
    soundInititated && setPlaying(true); //override browser autoplay policy, if present
  }, [soundInititated]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      updateNavScroll(NAVIGATION_REFS[currentNav])
    );

    return () =>
      window.removeEventListener(
        "resize",
        updateNavScroll(NAVIGATION_REFS[currentNav])
      );
  }, [currentNav, NAVIGATION_REFS]);

  return (
    <div className="container">
      <Loader promises={loaderPromises} afterDone={NAVIGATION.START}>
        <Sound playing={playing} setPlaying={setPlaying} />
        <Menu navigation={NAVIGATION} current={currentNav} />
        <Entry next={NAVIGATION.INTRO} innerRef={entryRef} />
        <Intro innerRef={introRef} next={NAVIGATION.PANORAMA} />
        <Panorama innerRef={panoramaRef} next={NAVIGATION.DATA} />
        <div ref={dataRef}>
          <DataPanel
            innerRef={dataPanelRef}
            dataKey="15. Armia Chrobrego - Polska"
            back={NAVIGATION.PANORAMA}
          />
        </div>
      </Loader>
    </div>
  );
};

export default App;
