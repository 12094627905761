import { FONTS } from "utils/assetsConstants";

//TODO: polyfill or fallback for older browsers after testing

const getFontPromises = () => {
  const fontPromises = [];
  for (const font of FONTS) {
    const fontFace = new FontFace(
      font.family,
      font.src.join(),
      font.descriptors
    );
    fontPromises.push(() =>
      fontFace.load().then(() => document.fonts.add(fontFace))
    );
  }
  return fontPromises;
};

export default getFontPromises;
