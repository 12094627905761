import { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

const AudioTrack = ({ audio, title, playing, setPlaying, btnClass }) => {
  const [muted, setMuted] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        playing ? await audio.play() : await audio.pause();
      } catch (e) {
        setPlaying(false);
        if (e.name !== "NotAllowedError") throw e;
      }
    })();
  }, [audio, playing, setPlaying]);

  useEffect(() => {
    audio.muted = muted;
  }, [muted, audio]);

  useEffect(() => (audio.loop = true), [audio]);

  return (
    <div
      className={classNames(styles.audioBtn, styles[btnClass], {
        [styles.active]: !muted,
      })}
      title={title}
      onClick={() => setMuted((muted) => !muted)}
    />
  );
};

export default AudioTrack;
