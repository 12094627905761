import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

const NAVIGATION_LABELS = {
  START: "Start",
  INTRO: "Wprowadzenie",
  PANORAMA: "Panorama",
  DATA: "Więcej o bitwie",
};

const Menu = ({ navigation, current }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <ul className={classNames(styles.menu, { [styles.hidden]: hidden })}>
      <li onClick={() => setHidden(!hidden)} className={styles.hide}>
        {hidden ? "menu" : "zwiń"}
      </li>
      {Object.entries(navigation).map(([key, next]) => (
        <li
          key={key}
          className={classNames({ [styles.active]: key === current })}
          onClick={() => next()}
        >
          {NAVIGATION_LABELS?.[key]}
        </li>
      ))}
    </ul>
  );
};

export default Menu;
