import { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Button from "components/button";
import getDynamicImage from "utils/getDynamicImage";

const initialStyleParams = {
  small: { top: 0, left: 0, display: "none" },
  expanded: false,
  inverted: false,
};

const Tooltip = ({
  text,
  textExtended,
  animated,
  expanded,
  fading,
  inverted,
  expand,
  close,
  fadeOut,
  innerRef,
  hideBtn,
  ...styleParams
}) => {
  const combinedStyleParams = { ...initialStyleParams, ...styleParams };
  const textExtendedRef = useRef(null);

  useEffect(() => {
    expanded && (textExtendedRef.current.scrollTop = 0);
  }, [expanded]);

  return (
    <div
      className={classNames(styles.tooltip, {
        [styles.expanded]: expanded,
        [styles.animated]: animated,
        [styles.fading]: fading,
        [styles.inverted]: inverted,
      })}
      style={combinedStyleParams[expanded ? "big" : "small"]}
    >
      <div ref={innerRef} className={styles.tooltipContent}>
        <div className={styles.close} onClick={expanded ? fadeOut : close} />
        <div
          className={classNames(styles.tooltipText, {
            [styles.hidden]: expanded,
          })}
        >
          <div>{text}</div>
          {!hideBtn && <Button label="Więcej" onClick={expand} />}
        </div>

        <div
          className={classNames(styles.tooltipTextExtended, {
            [styles.hidden]: !expanded,
            [styles.visible]: expanded,
          })}
          ref={textExtendedRef}
        >
          {textExtended?.img && (
            <img
              src={getDynamicImage(textExtended.img.src)}
              alt={textExtended.img.alt}
            />
          )}
          <h1>{text}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: textExtended?.text || textExtended,
            }}
          />
          <Button
            label="Zamknij"
            className={styles.tooltipButton}
            onClick={fadeOut}
          />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
