import img from "assets/images/panorama.jpg";
import styles from "./styles.module.scss";
import {
  handleDragStart,
  handleDragStop,
  handleDrag,
} from "utils/interactions";
import percent from "utils/percent";

const Preview = ({
  params: { markerWidth, markerLeft },
  setParams,
  sliderRef,
  isDragged,
  setIsDragged,
  onTop,
}) => {
  return (
    <div className={styles.preview} style={{ zIndex: onTop ? 2 : 1 }}>
      <div
        className={styles.markerBg}
        style={{ width: percent(markerLeft), left: 0 }}
      />
      <div
        onMouseDown={handleDragStart({ setIsDragged })}
        onMouseMove={handleDrag({ isDragged, setParams, sliderRef })}
        onMouseUp={handleDragStop({ setIsDragged })}
        onMouseOut={handleDragStop({ setIsDragged })}
        className={styles.marker}
        style={{ width: percent(markerWidth), left: percent(markerLeft) }}
      />
      <div
        className={styles.markerBg}
        style={{
          width: percent(100 - markerLeft - markerWidth),
          right: 0,
        }}
      />
      <img src={img} alt="Panorama bitwy nad Bugiem 1018" />
    </div>
  );
};

export default Preview;
