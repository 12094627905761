import { useRef, useEffect, useLayoutEffect, useState } from "react";
import PanoramaImg from "./panoramaImg";
import Tooltip from "components/tooltip";
import styles from "./styles.module.scss";
import Preview from "./preview";
import {
  scrollHorizontally,
  expandTooltip,
  closeTooltip,
  fadeOutTooltip,
  showSmallTooltip,
  calculatePreviewParams,
  updateSliderScroll,
} from "utils/interactions";

const initialPreviewParams = {
  markerWidth: 0,
  markerLeft: 0,
  offsetPercentRatio: 0,
};

const Panorama = ({ wrapperRef, setShowNextBtn }) => {
  const sliderRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltipParams, setTooltipParams] = useState({});
  const [previewParams, setPreviewParams] = useState(initialPreviewParams);
  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    setPreviewParams(calculatePreviewParams({ wrapperRef: sliderRef }));
    const sliderRefEl = sliderRef.current;
    sliderRefEl.addEventListener(
      "scroll",
      updateSliderScroll({ wrapperRef: sliderRef, setPreviewParams, isDragged })
    );

    window.addEventListener(
      "resize",
      updateSliderScroll({ wrapperRef: sliderRef, setPreviewParams })
    );

    return () => {
      sliderRefEl.removeEventListener(
        "scroll",
        updateSliderScroll({ wrapperRef: sliderRef, setPreviewParams })
      );

      window.removeEventListener(
        "resize",
        updateSliderScroll({ wrapperRef: sliderRef, setPreviewParams })
      );
    };
  }, [isDragged]);

  useEffect(() => {
    const sliderEl = sliderRef.current;
    !tooltipParams.expanded
      ? sliderEl.addEventListener("wheel", scrollHorizontally)
      : sliderEl.removeEventListener("wheel", scrollHorizontally);

    return () => sliderEl.removeEventListener("wheel", scrollHorizontally);
  }, [tooltipParams.expanded]);

  useLayoutEffect(() => {
    // console.log("First render");
  }, []);

  return (
    <>
      <div className={styles.slider} ref={sliderRef}>
        <Tooltip
          innerRef={tooltipRef}
          {...tooltipParams}
          expand={expandTooltip({
            wrapperRef: sliderRef,
            tooltipParams,
            setTooltipParams,
            setShowNextBtn,
          })}
          fadeOut={fadeOutTooltip({
            tooltipParams,
            setTooltipParams,
            wrapperRef,
            styles,
            setShowNextBtn,
          })}
          close={closeTooltip({
            wrapperRef: sliderRef,
            styles,
            setTooltipParams,
            setShowNextBtn,
          })}
        />
        <PanoramaImg
          onSelect={showSmallTooltip({
            tooltipRef,
            wrapperRef: sliderRef,
            setTooltipParams,
            styles,
          })}
        />
      </div>
      <Preview
        params={previewParams}
        setParams={setPreviewParams}
        sliderRef={sliderRef}
        isDragged={isDragged}
        setIsDragged={setIsDragged}
        onTop={!tooltipParams?.small}
      />
    </>
  );
};

export default Panorama;
