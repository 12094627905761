export const FONTS = [
  {
    family: "LatoWebBold",
    src: [
      'url("fonts/Lato/Lato-Bold.eot")',
      'url("fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-Bold.woff2") format("woff2")',
      'url("fonts/Lato/Lato-Bold.woff") format("woff")',
      'url("fonts/Lato/Lato-Bold.ttf") format("truetype")',
    ],
    descriptors: {
      style: "normal",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
  {
    family: "LatoWebBold",
    src: [
      'url("fonts/Lato/Lato-BoldItalic.eot")',
      'url("fonts/Lato/Lato-BoldItalic.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-BoldItalic.woff2") format("woff2")',
      'url("fonts/Lato/Lato-BoldItalic.woff") format("woff")',
      'url("fonts/Lato/Lato-BoldItalic.ttf") format("truetype")',
    ],
    descriptors: {
      style: "italic",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
  {
    family: "LatoWebLight",
    src: [
      'url("fonts/Lato/Lato-LightItalic.eot")',
      'url("fonts/Lato/Lato-LightItalic.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-LightItalic.woff2") format("woff2")',
      'url("fonts/Lato/Lato-LightItalic.woff") format("woff")',
      'url("fonts/Lato/Lato-LightItalic.ttf") format("truetype")',
    ],
    descriptors: {
      style: "italic",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
  {
    family: "LatoWebLight",
    src: [
      'url("fonts/Lato/Lato-Light.eot")',
      'url("fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-Light.woff2") format("woff2")',
      'url("fonts/Lato/Lato-Light.woff") format("woff")',
      'url("fonts/Lato/Lato-Light.ttf") format("truetype")',
    ],
    descriptors: {
      style: "normal",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
  {
    family: "LatoWeb",
    src: [
      'url("fonts/Lato/Lato-Regular.eot")',
      'url("fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-Regular.woff2") format("woff2")',
      'url("fonts/Lato/Lato-Regular.woff") format("woff")',
      'url("fonts/Lato/Lato-Regular.ttf") format("truetype")',
    ],
    descriptors: {
      style: "normal",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
  {
    family: "LatoWeb",
    src: [
      'url("fonts/Lato/Lato-Italic.eot")',
      'url("fonts/Lato/Lato-Italic.eot?#iefix") format("embedded-opentype")',
      'url("fonts/Lato/Lato-Italic.woff2") format("woff2")',
      'url("fonts/Lato/Lato-Italic.woff") format("woff")',
      'url("fonts/Lato/Lato-Italic.ttf") format("truetype")',
    ],
    descriptors: {
      style: "italic",
      weight: "normal",
      "text-rendering": "optimizeLegibility",
    },
  },
];
