import classNames from "classnames";
import styles from "./styles.module.scss";

const Title = ({ text, main }) => (
  <h1 className={classNames({ [styles.title]: true, [styles.main]: main })}>
    {text}
  </h1>
);

export default Title;
