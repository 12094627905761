import { useState, useEffect } from "react";

const Loader = ({ promises, children, afterDone }) => {
  const promisePercent = parseInt(100 / promises.length, 10);
  const [counter, setCounter] = useState(promisePercent);
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const handlePromises = async () => {
      await Promise.all([
        ...promises.map(async (promiseThunk) => {
          await promiseThunk();
          setCounter((counter) => {
            return counter + promisePercent;
          });
        }),
      ]);

      setDone(true);
      setCounter(100);
    };

    !done && handlePromises();
  }, [done, promises, promisePercent]);

  useEffect(() => {
    const timeout = setTimeout(
      () => progress < counter && setProgress(progress + 1),
      40
    );

    return () => clearTimeout(timeout);
  }, [counter, progress]);

  useEffect(() => {
    progress === 100 && done && afterDone();
  }, [progress, done, afterDone]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          fontFamily: "Arial sans-serif",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 0,
            width: "100%",
            height: `${progress}vh`,
            transition: "height 0.2s",
            backgroundColor: "#062340",
          }}
        />
        <div
          style={{
            fontSize: "2rem",
            opacity: 0.6,
            color: progress < 55 ? "#000" : "#fff",
            transition: "color 0.5s",
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {progress > 0 ? ` ${progress}%` : "Pobieranie..."}
        </div>
      </div>
      {progress === 100 && done && children}
    </>
  );
};

export default Loader;
