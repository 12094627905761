import getDynamicImage from "utils/getDynamicImage";

const images = require
  .context("assets/images", false, /\.(png|gif|ico|jpg|jpeg)$/)
  .keys();

const getImagePromises = () => {
  const imagePromises = [];
  for (const imageModule of images) {
    const imgPromise = () =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.src = getDynamicImage(imageModule.substring(2));
        image.onload = resolve;
        image.onerror = reject;
      });
    imagePromises.push(imgPromise);
  }
  return imagePromises;
};

export default getImagePromises;
