import { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import music from "assets/sound/music.mp3";
import battle from "assets/sound/battle.mp3";
import AudioTrack from "./audioTrack";

const AUDIO_TRACKS = [
  { src: music, title: "Muzyka", btnClass: "music" },
  { src: battle, title: "Efekty", btnClass: "effects" },
];

const prepareAudio = async () => {
  const promises = AUDIO_TRACKS.map(({ src, title, btnClass }) => {
    const audio = new Audio(src);
    return new Promise((resolve) => {
      audio.addEventListener("canplaythrough", (e) =>
        resolve({ audio, title, btnClass })
      );
    });
  });

  return Promise.all(promises);
};

const Sound = ({ playing, setPlaying }) => {
  const [audioEls, setAudioEls] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    (async () => {
      const audioEls = await prepareAudio();
      setAudioEls(audioEls);
    })();
  }, []);

  return (
    audioEls && (
      <div
        className={classNames(styles.container, {
          [styles.active]: menuActive,
        })}
      >
        <div>
          <div
            className={classNames(styles.soundBtn, {
              [styles.active]: playing,
            })}
            onClick={() => setPlaying(!playing)}
            title={playing ? "Wyłącz dźwięk" : "Włącz dźwięk"}
          />
          <div
            className={classNames(styles.soundMenuBtn, {
              [styles.active]: menuActive,
            })}
            onClick={() => setMenuActive(!menuActive)}
            title="Ustawienia dźwięku"
          />
        </div>
        <div>
          {audioEls.map(({ audio, title, btnClass }) => (
            <AudioTrack
              audio={audio}
              key={title}
              title={title}
              playing={playing}
              setPlaying={setPlaying}
              btnClass={btnClass}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default Sound;
