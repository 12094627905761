export const PANORAMA_TEXTS_SMALL = {
  "1. Bug": "Bug",
  "2. Wojska najemnikw - Rus": "Waregowie",
  "3. Umocnienia brzegowe - Rus": {
    hideBtn: true,
    text: "Umocnienia brzegowe strony ruskiej",
  },
  // "4. Stary Budy - Rus": "Stary Budy - Ruś",
  "5. swita przyboczna - Rus": "Początek bitwy",
  "6. Sztandar - Rus": { hideBtn: true, text: "Sztandar strony ruskiej" },
  "7. Jarek - Rus": "Jarosław Mądry",
  "8. Obozowisko - Rus": { hideBtn: true, text: "Obozowisko wojsk ruskich" },
  "9. Trzon Armii - Rus": "Porażka Jarosława",
  "10. Konnica - Rus": "Ucieczka Jarosława",
  "11. Prowizoryczna przeprawa - Polska": "Niedokończony most",
  "12. Harcownicy - Polska": "Harcownicy",
  "13. Oboz na zboczu - Polska": { hideBtn: true, text: "Obóz na zboczu" },
  "14. Grodek": "Gród Wołyń",
  "15. Armia Chrobrego - Polska": "Armia Chrobrego",
  "16. Bolek Chrobry - Polska": "Bolesław Chrobry",
  "17. przyboczni krola - Polska": "Przyboczni króla",
  "18. Sztandar z Orlem - Polska": "Sztandar Bolesława Chrobrego",
  "19. Konni najemnicy - Polska": "Jednostki sprzymierzone",
};

export const PANORAMA_TEXTS_BIG = {
  "1. Bug": `<p>
  Rzeka będąca dziś w większości swojego przebiegu granicą pomiędzy Polską a Ukrainą i
Białorusią. Stanowi naturalną przeszkodę w komunikacji między wschodem, a zachodem. Nie
zawsze tak jednak było. Przez wieki stanowiła, wraz z dopływami (np. Huczwa), jeden z
podstawowych szlaków komunikacyjnych na tym terenie. Do dziś w jej nurtach ujawniane są
ciekawe zabytki archeologiczne pochodzące z różnych epok.
  </p>`,
  "2. Wojska najemnikw - Rus": `
  <div class="quote">
      <p>
        Jarosław zaś zebrawszy Ruś i Waregów, i Słowian, poszedł przeciw Bolesławowi i
Światopełkowi, i przyszedł ku grodowi Wołyń, i stali po dwóch stronach rzeki Bug.
      </p>
      <p class="signature">
        Powieść minionych lat, roz. 48
      </p>
      </div>
      <p>
       Armia ruska nie była jednolita etnicznie. Dynasta Rurykowiczów miała pochodzenie
skandynawskie, stąd też liczne jednostki Waregów – Wikingów, wspierające Jarosława Mądrego. Ponadto z kronik wiemy, że jeszcze w X wieku w armii Włodzimierza Wielkiego,
ojca Jarosława, mogli znajdować się wciąż zamieszkujący tereny jego państwa Goci.
</p>
  `,
  "3. Umocnienia brzegowe - Rus": "Umocnienia brzegowe - Ruś",
  "4. Stary Budy - Rus": "Stary Budy - Ruś",
  "5. swita przyboczna - Rus": `
  <p>
  Wszyscy kronikarze wskazują na utarczkę słowną która miała miejsce na samym początku
bitwy. Tak opisał ja Gall Anonim:
</p>
<div class="quote">
      <p>
A skoro doniesiono ruskiemu królowi, że Bolesław już przeszedł na drugi brzeg rzeki i wraz ze
swoim wojskiem zatrzymał się na pograniczu jego królestwa, nierozsądny król przypuszczając,
że go osaczył swymi masami wojska jak zwierza w sieci, przesłał mu podobno słowa pełne
wielkiej pychy, które spaść miały na jego własną głowę: „Niechaj wie Bolesław, że jako wieprz
w kałuży otoczony jest przez moje psy i łowców” A na to król polski odpowiedział: „Dobrze,
owszem, nazwałeś mnie wieprzem w kałuży, ponieważ we krwi łowców i psów twoich to jest
książąt i rycerzy, ubroczę kopyta koni moich, a ziemię twą i miasta spustoszę jak dzik odyniec.
</p>
<div class="signature">
Anonim tzw. Gall, ks. I
</div>
</div>
<p>

A tak autor Powieści minionych lat:
</p>
<div class="quote">
<p>I miał Jarosław piastuna i wojewodę imieniem Budy, i począł on lżyć Bolesława, mówiąc: „Oto
ci przebodziem oszczepem brzuch twój tłusty”. Był bowiem Bolesław wielki i ciężki, że i na
koniu ledwo mógł siedzieć, lecz był roztropny. I rzekł Bolesław do drużyny swojej: „ Jeśli was
ta obelga nie obraża, to ja polegnę sam”.</p>
<div class="signature">
Powieść minionych lat, roz. 48
</div>
</div> 
  `,
  "6. Sztandar - Rus": "Sztandar - Ruś",
  "7. Jarek - Rus": `<p>
  Jeden z najwybitniejszych władców w dziejach Rusi. Przez cały okres swojego panowania
musiał zmagać się z licznymi buntami i najazdami. To za czasów jego panowania Kijów został
znacząco rozbudowany i stał się jedną z najważniejszych światowych metropolii. Relacje Rusi
za czasów jego panowania z państwem polskim były złożone. To on doprowadził do ślubu
swojego syna Izasława z córką Mieszka II Gertrudą. Warto również nadmienić że w roku 1047
wojska Jarosława Mądrego wspierały Kazimierza Odnowiciela w jego walce ze zbuntowanym
Miesławem.
  </p>`,
  "8. Obozowisko - Rus": "Obozowisko - Ruś",
  "9. Trzon Armii - Rus": `
    <p>
    Z opisu bitwy wiemy, ze armia ruska była zaskoczona obecnością wojsk Bolesława Chrobrego
nad Bugiem. W momencie spotkania, budowane przez stronę polską przeprawy wciąż były
jeszcze nieskończone, dlatego w szeregach wojowników ruskich panowało odprężenie. Nagłe
przeprawienie się jednostek Bolesława zupełnie zaskoczyło przeciwnika. Jak napisał
kronikarz:
    </p>
    <div class="quote">
      <p>
        Tymczasem Polacy wyzywając nieprzyjaciela, skłonili go do walki i z niespodziewanym szczęściem
odepchnęli go od rzeki, którą obsadził. 6,18
      </p>
      <p>
      Zgiełk walki podniecił Bolesława. Zachęciwszy swych towarzyszy do gotowości do walki i pośpiechu,
przeprawił się szybko, choć z wielkim trudem przez rzekę.
      </p>
      <p>
      Wojsko nieprzyjacielskie, ustawione naprzeciw w zwartych oddziałach, nadmiernie usiłowało bronić
swojej ojczyzny. Albowiem ustąpiło już przy pierwszym starciu i nie stawiało potem silniejszego oporu.
      </p>
      <p>
      Poległa tam bardzo duża liczba spośród uciekających. Mała zaś spośród zwycięzców.
      </p>
      <p>
      Od tej chwili Bolesław ścigał z wymarzonym wprost powodzeniem znajdujących się w rozsypce
nieprzyjaciół, a mieszkańcy przyjmowali go wszędzie z wielkimi honorami i darami.
      </p>
      <p class="signature">
        Kronika Thietmara, ks. VIII
      </p>
      </div>
    `,
  "10. Konnica - Rus": `
  <p>
  Po przegranej bitwie Jarosław tylko z czterema towarzyszami uciekł do Nowogrodu Wielkiego.
Bolesław zaś na tronie kijowskim osadził swojego zięcia Światopełka. Już w 1019 roku Swiatopełk
został jednak odsunięty od władzy w wyniku buntu możnych. Bolesław Chrobry nie podjął
kolejnej interwencji w Kijowie, tylko na nowo ułożył stosunki dyplomatyczne z Jarosławem Mądrym.
  </p>
  `,
  "11. Prowizoryczna przeprawa - Polska": `<p>
  Bolesław Chrobry przybywszy nad Bug rozkazał budowę drewnianego mostu, którym jego
wojska przeprawiłyby się przez rzekę. Nieoczekiwany obrót wypadków, utarczka słowna
pomiędzy stronami, sprawił jednak, że siły polskie przeprawiły się przez wodę nie czekając na
dokończenie prac.
  </p>`,
  "12. Harcownicy - Polska": `<p>Jak napisał Gall Anonim:</p>
  <div class="quote">
      <p>
  Król Bolesław najechał Ruś i król Rusinów Polskę, jeden nie wiedząc
o drugim i każdy rozbił obóz u granic ziemi drugiego, przedzielała ich tylko rzeka.
</p>
<p class="signature">
        Anonim tzw. Gall, ks. I
      </p>
</div>
<p>
Opisy bitwy wyraźnie wskazują na zaskoczenie obydwu stron spotkaniem nad Bugiem opodal
grodu Wołyń. Źródła wskazują, że to właśnie ostra wymiana wzajemnych obelg nad wodą
doprowadziła do nieoczekiwanego przez Rusinów ataku strony Polskiej. Most na rzece był
wciąż niegotowy.</p>`,
  "13. Oboz na zboczu - Polska": "",
  "14. Grodek": {
    img: { src: "grodek.png", alt: "gródek" },
    text: `
    <p>
      Wołyń
Grodzisko w Gródku w powiecie hrubieszowskim utożsamiane jest ze wzmiankowany w
źródłach grodem Wołyń. Znajduje się u ujścia Huczwy do Bugu i przez mieszkańców regionu
nazywane jest Zamczyskiem. Do dziś dobrze widoczne są imponujące umocnienia dawnego
grodu – wał ziemy o szerokości 5-10 m i o wysokości 2- 4 metrów. Obszar wewnątrz wału
liczył ok 1 hektara, natomiast cały zespół osadniczy mógł mieć ponad 15h. Grod Wołyń był
jednym z najważniejszych ośrodków zespołu osadniczego jaki stanowiły Grody Czerwieńskie.
    </p>
  `,
  },
  "15. Armia Chrobrego - Polska": {
    img: { src: "druzyna.png", alt: "drużyna" },
    text: `
    <p>
      Opis wojsk za czasów Bolesława Chrobrego pozostawił nam Gall Anonim:
    </p>
    
    <div class="quote">
      <p>
        Bo jakiż to rachmistrz potrafiłby mniej więcej pewną cyfrą określić żelazne jego hufce, a cóż
dopiero przytoczyć opisy zwycięstw i tryumfów takiego ich mnóstwa! Z Poznania bowiem miał
1300 pancernych i 4000 tarczowników, z Gniezna 1500 pancernych i 5000 tarczowników, z
grodu Władysława 800 pancernych i 2000 tarczowników, z Giecza 300 pancernych i 2000
tarczowników,
      </p>
      <p>
        Ci wszyscy waleczni i wprawni w rzemiośle wojennym występowali do boju za czasów
Bolesława Wielkiego.
</p>
      <p>
…więcej miał król Bolesław pancernych, niż cała Polska ma za naszych czasów tarczowników;
za czasów Bolesława tyle prawie było w Polsce rycerzy, ile za naszych czasów znajduje się
ludzi wszelkiego stanu. (Anonim tzw. Gall, ks. I)
      </p>
      <p class="signature">
        Anonim tzw. Gall, ks. I
      </p>
    </div>`,
  },
  "16. Bolek Chrobry - Polska": `<p>Jeden z najwybitniejszych władców w dziejach Polski. Pierwszy król koronowany w 1025 roku.
Dzięki opisowi bitwy wiemy, że był otyły, o czym możemy się dowiedzieć z fragmentu
„Powieści minionych lat”:</p>
<div class="quote">
<p>I miał Jarosław piastuna i wojewodę imieniem Budy, i począł on lżyć Bolesława, mówiąc: „Oto
ci przebodziem oszczepem brzuch twój tłusty”. Był bowiem Bolesław wielki i ciężki, że i na
koniu ledwo mógł siedzieć, lecz był roztropny. I rzekł Bolesław do drużyny swojej: „Jeśli was
ta obelga nie obraża, to ja polegnę sam”</p>
</div>
<p>Bolesław musiał być jednak osobą niezwykle silną i sprawną fizycznie, ponieważ przebywał w
nieustannym ruchu przemierzając konno całe terytorium swojego państwa.</p>`,
  "17. przyboczni krola - Polska": `<p>Najważniejsi urzędnicy stanowili bezpośrednie otoczenie władcy zarówno na dworze
książęcym, jak i na polu bitwy. Stanowiąc doborową jednostkę chroniącą władcę.</p>`,
  "18. Sztandar z Orlem - Polska": `<p>Do naszych czasów nie przetrwał żaden z wizerunków sztandarów wojsk Bolesława. Z opisów
kronikarskich wiemy jednak, że istniały. Kronikarze nie uznali za istotne, aby je szczegółowo
opisać. Ówczesne monety, przynoszą pewien zbiór wyobrażeń, jednak żadne z nich nie może
być uznane za herb władcy, ponieważ takowy się jeszcze nie wykształcił. Orzeł był jednym z
typowych dla średniowiecza symboli władzy i nie można wykluczyć, że mógł być
wykorzystywany na chorągwiach Bolesława Chrobrego.</p>`,
  "19. Konni najemnicy - Polska": `<p>Wojska Bolesława Chrobrego były wspierane przez sojuszników. W części jednostki najemne.
Bolesława wspomagało 300 jeźdźców niemieckich, 500 Węgrów oraz około 1000 Pieczyngów.</p>`,
};
