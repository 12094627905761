import { useState } from "react";
import Panorama from "./panorama";
import styles from "./styles.module.scss";
import Button from "components/button";
import Magnifier from "components/magnifier";
import { isMobile, BrowserView } from "react-device-detect";

const PanoramaWrapper = ({ innerRef, next }) => {
  const wrapperRef = innerRef;
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [magnifier, setMagnifier] = useState(false);

  return (
    <div className={styles.container} ref={wrapperRef}>
      <BrowserView>
        <span
          className={styles.magnifierSwitch}
          onClick={() =>
            setMagnifier((magnifier) => (isMobile ? false : !magnifier))
          }
        >
          {magnifier ? "Panorama" : "Powiększenie"}
        </span>
      </BrowserView>
      {magnifier ? (
        <Magnifier />
      ) : (
        <Panorama wrapperRef={wrapperRef} setShowNextBtn={setShowNextBtn} />
      )}
      {showNextBtn && (
        <Button
          label="Przejdź dalej"
          className={styles.button}
          onClick={next}
        />
      )}
    </div>
  );
};

export default PanoramaWrapper;
