import { PANORAMA_TEXTS_SMALL, PANORAMA_TEXTS_BIG } from "data/constants";
import throttle from "utils/throttle";

export const scrollHorizontally = (e) => {
  e.preventDefault();
  const el = e.currentTarget;
  el.scroll({
    left: el.scrollLeft + e.deltaY * 2,
    behavior: "smooth",
  });
};

export const resetSelectedNodes = ({ wrapperRef, styles }) => {
  const activeNodes = wrapperRef.current.getElementsByClassName(styles.active);
  for (let i = 0; i < activeNodes.length; i++) {
    activeNodes[i].classList.remove(styles.active);
  }
};

export const showSmallTooltip =
  ({ wrapperRef, tooltipRef, setTooltipParams, styles }) =>
  (e) => {
    if (e.target.className.baseVal !== styles.highlightedLayer) return;
    resetSelectedNodes({ wrapperRef, styles });
    e.target.classList.add(styles.active);
    const textIdx = e.target?.parentElement?.dataset?.name;

    const wrapperEl = wrapperRef.current;
    const wrapperScrollLeft = wrapperEl.scrollLeft;
    const { y: wrapperY } = wrapperEl.getBoundingClientRect();
    const {
      x: targetX,
      y: targetY,
      width: targetWidth,
      height: targetHeight,
    } = e.target.getBoundingClientRect();
    const roundMarkerHeight = 26;
    const paddingOffset = parseInt(
      window.getComputedStyle(wrapperEl, null).paddingTop,
      10
    );
    const tooltipWidth = parseInt(
      window.getComputedStyle(tooltipRef.current, null).width,
      10
    );
    const targetOffsetToWindow = targetX + targetWidth / 2;

    const tooltipLeft = wrapperScrollLeft + targetOffsetToWindow;

    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    if (tooltipLeft - wrapperScrollLeft >= windowWidth) {
      wrapperEl.scroll({
        left: tooltipLeft - windowWidth + roundMarkerHeight,
        behavior: "smooth",
      });
    }

    if (tooltipLeft - wrapperScrollLeft <= 0) {
      wrapperEl.scroll({
        left: tooltipLeft - roundMarkerHeight,
        behavior: "smooth",
      });
    }

    setTooltipParams({
      small: {
        left: tooltipLeft,
        height:
          targetY +
          targetHeight / 2 -
          wrapperY -
          roundMarkerHeight -
          paddingOffset,
      },
      text: PANORAMA_TEXTS_SMALL[textIdx]?.text
        ? PANORAMA_TEXTS_SMALL[textIdx]?.text
        : PANORAMA_TEXTS_SMALL[textIdx],
      hideBtn: PANORAMA_TEXTS_SMALL[textIdx]?.hideBtn,
      textIdx,
      expanded: false,
      animated: false,
      inverted: targetOffsetToWindow + tooltipWidth > windowWidth,
    });
  };

export const expandTooltip =
  ({ wrapperRef, tooltipParams, setTooltipParams, setShowNextBtn }) =>
  (e) => {
    const wrapperScrollLeft = wrapperRef.current.scrollLeft;

    setShowNextBtn(false);

    setTooltipParams({
      ...tooltipParams,
      big: {
        left: wrapperScrollLeft,
      },
      textExtended: PANORAMA_TEXTS_BIG[tooltipParams.textIdx],
      expanded: true,
      animated: true,
    });

    console.log(
      tooltipParams.textIdx,
      PANORAMA_TEXTS_BIG[tooltipParams.textIdx],
      PANORAMA_TEXTS_BIG
    );
  };

export const closeTooltip =
  ({ setTooltipParams, wrapperRef, styles }) =>
  (e) => {
    setTooltipParams({});
    resetSelectedNodes({ wrapperRef, styles });
  };

export const fadeOutTooltip =
  ({ tooltipParams, setTooltipParams, wrapperRef, styles, setShowNextBtn }) =>
  (e) => {
    setTooltipParams({
      ...tooltipParams,
      fading: true,
    });

    setTimeout(() => {
      setShowNextBtn(true);
      closeTooltip({ setTooltipParams, wrapperRef, styles })();
    }, 2000);
  };

export const calculatePreviewParams = ({ wrapperRef }) => {
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const wrapperEl = wrapperRef.current;
  const panoramaImgEl = wrapperEl.querySelector("image");
  const { width: panoramaImgWidth } = panoramaImgEl.getBoundingClientRect();
  const visiblePercent = (windowWidth / panoramaImgWidth) * 100;

  const wrapperScrollLeft = wrapperEl.scrollLeft;
  const offsetPercent = (wrapperScrollLeft / panoramaImgWidth) * 100;

  return {
    markerWidth: visiblePercent,
    markerLeft: offsetPercent,
  };
};

export const updateSliderScroll = ({
  wrapperRef,
  setPreviewParams,
  isDragged,
}) =>
  throttle(
    () =>
      !isDragged && setPreviewParams(calculatePreviewParams({ wrapperRef })),
    20
  );

export const handleDragStart =
  ({ setIsDragged }) =>
  (e) => {
    e.preventDefault();
    e.target.style.cursor = "grabbing";
    setIsDragged(true);
  };

const updateScrollOnDrag = ({ sliderRef, updatedMarkerLeft }) => {
  const newScrollLeft =
    (sliderRef.current.scrollWidth * updatedMarkerLeft) / 100;

  sliderRef.current.scroll({
    left: newScrollLeft,
  });
};

export const handleDrag =
  ({ isDragged, setParams, sliderRef }) =>
  (e) => {
    e.preventDefault();

    if (!isDragged) return;

    const previewWidth = e.target.parentElement.clientWidth;
    const draggedWidthPercent = (e.target.clientWidth / previewWidth) * 100;
    const maxMarker = 100 - draggedWidthPercent;
    const draggedOffset = (e.nativeEvent.movementX / previewWidth) * 100;

    setParams((params) => {
      const updatedMarkerLeft = Math.min(
        maxMarker,
        Math.max(0, params.markerLeft + draggedOffset)
      );

      updateScrollOnDrag({ sliderRef, updatedMarkerLeft });

      return {
        ...params,
        markerLeft: updatedMarkerLeft,
      };
    });
  };

export const handleDragStop =
  ({ setIsDragged }) =>
  (e) => {
    e.preventDefault();
    e.target.style.cursor = "";
    setIsDragged(false);
  };

export const handleMagnifierDrag =
  ({ isDragged, glassWidth, glassHeight, setGlassParams }) =>
  (e) => {
    e.preventDefault();

    if (!isDragged) return;

    const previewWidth = e.target.parentElement.clientWidth;
    const maxLeft = 100 - glassWidth;
    const draggedOffsetX = (e.nativeEvent.movementX / previewWidth) * 100;

    const previewHeight = e.target.parentElement.clientHeight;
    const maxTop = 100 - glassHeight;
    const draggedOffsetY = (e.nativeEvent.movementY / previewHeight) * 100;

    setGlassParams((glassParams) => {
      const updatedLeft = Math.min(
        maxLeft,
        Math.max(0, glassParams.left + draggedOffsetX)
      );

      const updatedTop = Math.min(
        maxTop,
        Math.max(0, glassParams.top + draggedOffsetY)
      );

      return {
        ...glassParams,
        left: updatedLeft,
        top: updatedTop,
      };
    });
  };

export const calcMagnifierParams = ({
  magnifiedContainerRef,
  setMagnifiedParams,
  setGlassParams,
  ratio,
}) => {
  const { width: magnifiedContainerWidth, height: magnifiedContainerHeight } =
    magnifiedContainerRef.current.getBoundingClientRect();

  const imgRatio = magnifiedContainerHeight / magnifiedContainerWidth;

  const glassHeight = ratio;
  const glassWidth = glassHeight * imgRatio;

  setMagnifiedParams({
    width: (magnifiedContainerWidth * 100) / glassWidth,
    height: (magnifiedContainerHeight * 100) / glassHeight,
  });
  setGlassParams((glassParams) => ({
    ...glassParams,
    width: glassWidth,
    height: glassHeight,
  }));
};
