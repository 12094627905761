import styles from "./styles.module.scss";
import Button from "components/button";
import Title from "components/title";
import YouTube from "react-youtube";
import classNames from "classnames";
import logo from "assets/images/logo.jpg";

const dataPanel = ({ innerRef, back }) => {
  return (
    <div
      className={classNames([styles.container, styles.dataContainer])}
      ref={innerRef}
    >
      <Title text="Bitwa nad Bugiem" />
      <section>
        <h2>Film o bitwie:</h2>
        <div className={styles.video}>
          <YouTube
            videoId="dfoBaALrLZY"
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </section>
      <section>
        <h2>
          <a
            href="https://sklep.mnwl.pl/katalog-produktow/236-historia-pewnego-zabytku.html"
            target="_blank"
            rel="noreferrer"
          >
            Komiks <i>Historia pewnego zabytku</i>
          </a>
        </h2>
      </section>
      <section>
        <h2>Autorzy:</h2>
        <dl>
          <dt>Koncepcja publikacji:</dt>
          <dd>Bartłomiej Bartecki, Marta Cyran, Marcin Gapski</dd>
          <dt>Projekt graficzny:</dt>
          <dd>Paweł Kurowski</dd>
          <dt>Konsultacje militariów i ubiorów</dt>
          <dd>Mateusz Lis, Krystian Psujek, Bartosz Ligocki</dd>
          <dt>Projekt i wykonanie aplikacji:</dt>
          <dd>Piotr Wojcieszuk, Agnieszka Kamińska</dd>
          <dt>Muzyka:</dt>
          <dd>Maciej Wojcieszuk</dd>
        </dl>
      </section>
      <section>
        <h2>
          Portal powstał na zamówienie Muzeum Ziem Wschodnich Dawnej
          Rzeczpospolitej
        </h2>
        <a class={styles.logo} href="https://ziemiewschodnie.pl">
          <img
            alt="Muzeum Ziem Wschodnich Dawnej Rzeczpospolitej"
            width="200"
            height="200"
            src={logo}
          />
        </a>
      </section>
      <Button label="Wróć do panoramy" onClick={back} />
    </div>
  );
};

export default dataPanel;
