import styles from "./styles.module.scss";
import Button from "components/button";
import Title from "components/title";
import bgImg from "assets/images/bg.jpg";

const Entry = ({ next, innerRef }) => {
  return (
    <div
      className={styles.container}
      ref={innerRef}
      style={{ backgroundImage: `url(${bgImg})`, backgroundSize: "cover" }}
    >
      <div className={styles.cta}>Poznaj historię</div>
      <Title text="Bitwa nad Bugiem" main />
      <Button label="Wejdź" cta yellowWhite onClick={next} />
    </div>
  );
};

export default Entry;
