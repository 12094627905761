import classNames from "classnames";
import styles from "./styles.module.scss";

const Button = ({
  label,
  cta,
  yellow,
  yellowWhite,
  className: customClassName,
  onClick,
  innerRef,
}) => (
  <button
    ref={innerRef}
    className={classNames(
      {
        [styles.btn]: true,
        [styles.yellow]: yellow,
        [styles.yellowWhite]: yellowWhite,
        [styles.cta]: cta,
      },
      customClassName
    )}
    onClick={onClick}
  >
    {label}
  </button>
);

export default Button;
