import { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import Button from "components/button";
import Title from "components/title";
import img3 from "assets/images/3.jpg";
import img4 from "assets/images/4.jpg";
import img5 from "assets/images/5.jpg";

const images = [img5, img3, img4];

const observer = new IntersectionObserver(
  (entry) => {
    entry[0].isIntersecting &&
      document.addEventListener("scroll", (e) => e.preventDefault());
  },
  { threshold: 1.0, rootMargin: "0px 0px -32px 0px" }
);

const Intro = ({ innerRef, next }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    observer.observe(buttonRef.current);
  });

  return (
    <div className={styles.container} ref={innerRef}>
      <div className={styles.images}>
        {images.map((img) => (
          <img src={img} alt="" key={img} />
        ))}
      </div>
      <Title text="Bitwa nad Bugiem" />
      <div className={styles.text}>
        <p>
          22 lipca 1018 w pobliżu grodu Wołyń, dzisiejszy Gródek nad Bugiem,
          rozegrała się wielka bitwa pomiędzy Bolesławem Chrobrym a Jarosławem
          Mądrym. Odniesione przez stronę polską zwycięstwo otworzyło
          Bolesławowi drogę do Kijowa, stolicy Rusi, którą zajął 14 sierpnia
          1018 roku.
        </p>
        <p>
          O zwycięstwie Bolesława Chrobrego doniesiono papieżowi oraz cesarzowi
          bizantyjskiemu. Wypadki które miały miejsce nad Bugiem stały się
          ważnym wydarzeniem o skali ogólnoeuropejskiej. Zaś opisy bitwy
          znalazły się w ówczesnych kronikach.
        </p>
      </div>
      <Button
        innerRef={buttonRef}
        label="Zobacz panoramę bitwy"
        onClick={next}
      />
    </div>
  );
};

export default Intro;
