import { useState, useRef, useEffect } from "react";
import img from "assets/images/panorama.jpg";
import styles from "./styles.module.scss";
import throttle from "utils/throttle";
import percent from "utils/percent";
import {
  handleDragStart,
  handleDragStop,
  handleMagnifierDrag,
  calcMagnifierParams,
} from "utils/interactions";

const initGlassParams = { top: 0, left: 0, width: 1, height: 1 };

const Magnifier = () => {
  const [glassParams, setGlassParams] = useState(initGlassParams);
  const [magnifiedParams, setMagnifiedParams] = useState({});
  const [isDragged, setIsDragged] = useState(false);
  const magnifiedContainerRef = useRef(null);
  const alt = "Panorama bitwy nad Bugiem 1018";
  const ratio = 50;

  useEffect(() => {
    calcMagnifierParams({
      magnifiedContainerRef,
      setMagnifiedParams,
      setGlassParams,
      ratio,
    });

    window.addEventListener(
      "resize",
      throttle(
        () =>
          calcMagnifierParams({
            magnifiedContainerRef,
            setMagnifiedParams,
            setGlassParams,
            ratio,
          }),
        100
      )
    );

    return () =>
      window.removeEventListener(
        "resize",
        throttle(
          () =>
            calcMagnifierParams({
              magnifiedContainerRef,
              setMagnifiedParams,
              setGlassParams,
              ratio,
            }),
          100
        )
      );
  }, [magnifiedContainerRef, ratio]);

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <img src={img} alt={alt} />
        <div
          className={styles.glass}
          style={{
            top: percent(glassParams.top),
            left: percent(glassParams.left),
            width: percent(glassParams.width),
            height: percent(glassParams.height),
          }}
          onMouseDown={handleDragStart({ setIsDragged })}
          onMouseMove={handleMagnifierDrag({
            isDragged,
            glassWidth: glassParams.width,
            glassHeight: glassParams.height,
            setGlassParams,
          })}
          onMouseUp={handleDragStop({ setIsDragged })}
          onMouseOut={handleDragStop({ setIsDragged })}
        />
      </div>
      <div className={styles.magnified} ref={magnifiedContainerRef}>
        <img
          src={img}
          style={{
            height: magnifiedParams.height,
            width: magnifiedParams.width,
            top: percent(-glassParams.top * (100 / glassParams.height)),
            left: percent(-glassParams.left * (100 / glassParams.width)),
          }}
          alt={alt}
        />
      </div>
    </div>
  );
};

export default Magnifier;
